import React, {useCallback, useState} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';

import Navbar from '../components/Elements/NavBar';
import Footer from '../components/Elements/Footer';

import './404.scss';

interface ErrorPageProps {

}

const menuItems = [
  {
    label: 'Research',
    linkTo: '/research'
  },
  {
    label: 'Policy',
    linkTo: '/policy'
  },
  {
    label: 'Practice',
    linkTo: '/practice'
  },
  {
    label: 'Resources',
    linkTo: '/resources'
  },
  {
    label: 'Contact us',
    linkTo: '/contact-us'
  },
];

const ErrorPage: React.FC<ErrorPageProps> = ({}: ErrorPageProps) => {
  
  return (
    <>
      <Helmet>
        <title>Page not found | March Legacy</title>
      </Helmet>
      <header>
        <Navbar slug="" menuItems={menuItems} />
      </header>
      <main>
        <div className="error-wrapper">
          Sorry, we couldn't find that page.
        </div>
      </main>
      <Footer />
    </>
  )
};

export default ErrorPage;
